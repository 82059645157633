declare var $: any;
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data.service';
import { FileService } from '../../services/file.service';
import { EditorCv, EditorInvoice } from '../../classes/editorCv';
import html2canvas from 'html2canvas';
import { TimelineItem } from 'src/app/classes/timelineItem';

@Component({
  selector: 'editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit {

  private cvData: EditorCv = {} as EditorCv;
  private invoiceData: EditorInvoice = {} as EditorInvoice;
  private loading: boolean = false;
  private exporting: boolean = false;
  private inEnglish: boolean = false;
  private isPrivate: boolean = false;
  private type: string = 'invoice';
  private fileName: string;
  private today: Date = new Date();
  private payDate: Date = new Date();
  private sum: number = 0;
  private sumVat: number = 0;

  authed: boolean = false;

  constructor(private dataService: DataService, private route: ActivatedRoute, private fileService: FileService) { }

  ngOnInit() {
    const userParam = this.route.snapshot.params['user'] || '';
    const decodedString = atob(userParam);
    this.authed = decodedString === 'jacob.haraldsson@precurse.se:bocajjacob84';

    if (this.authed) {
      this.getData();
    }
  }

  getData(inEnglish: boolean = false) {
    this.loading = true;
    this.inEnglish = inEnglish;

    if (this.type === 'precurse' || this.type === 'private') {
      this.dataService.getCvData(inEnglish).subscribe((data: EditorCv) => {
        this.cvData = data;
        this.fileName = 'CV ' + this.cvData.name + (this.inEnglish ? ' english' : '');

        if (data.imageName) {
          this.dataService.getImageLink(data.imageName).subscribe((link: string) => {
            this.cvData.imageLink = link;
            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      });
    } else if (this.type === 'invoice') {
      this.dataService.getInvoiceData().subscribe((data: EditorInvoice) => {
        this.invoiceData = data;
        const months = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'];
        const twoWeeksAgo = new Date();
        twoWeeksAgo.setDate(this.today.getDate() - 14);
        this.fileName = 'Faktura Precurse Consulting ' + months[twoWeeksAgo.getMonth()] + ' ' + twoWeeksAgo.getFullYear();
        const payDate = new Date();
        payDate.setDate(this.today.getDate() + data.paymentDays);
        let plusDays = 0;
        if (payDate.getDay() === 0) plusDays = 1; // Sunday
        if (payDate.getDay() === 6) plusDays = 2; // Saturday
        this.payDate = new Date();
        this.payDate.setDate(this.today.getDate() + (data.paymentDays + plusDays));
        this.updateSums(data.itemList);
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }

  changeType(type: string) {
    this.type = type;
    this.isPrivate = type === 'private';
    this.getData(this.inEnglish);
  }

  uploadSelectedImage(event: any) {
    this.loading = true;
    const file = event.target.files[0];

    this.dataService.uploadFile(file).subscribe(() => {
      this.cvData.imageName = file.name;
      this.loading = false;
    });
  }

  saveData() {
    this.loading = true;
    if (this.type === 'invoice') {
      this.dataService.updateInvoiceData(this.invoiceData).then(() => {
        this.loading = false;
      });
    } else {
      this.dataService.updateCvData(this.cvData).then(() => {
        this.loading = false;
      });
    }
  }

  savePdf() {
    this.exportPdf();
  }

  uploadPdf() {
    this.exportPdf(true);
  }

  saveDocx() {
    this.fileService.createDocx(this.cvData, this.inEnglish, this.isPrivate).subscribe(doc => {
      this.fileService.saveDocx(doc, this.fileName, this.inEnglish);
    });
  }

  updateSums(items: TimelineItem[]) {
    this.sum = items
      .map(item => (item.hours * item.rate))
      .reduce((prev, curr) => prev + curr, 0);
    this.sumVat = items
      .map(item => Math.round(item.hours * item.rate * 0.25))
      .reduce((prev, curr) => prev + curr, 0);
  }

  private exportPdf(upload?: boolean) {
    this.loading = true;
    this.exporting = true;
    $('html, body').scrollTop(0);
    var pdf = null;

    if (this.type === 'invoice') {
      var invoiceDiv = document.getElementById('invoiceDiv');
      html2canvas(invoiceDiv, { useCORS: true }).then(canvas => {
        const contentDataURL = canvas.toDataURL('image/png');
        pdf = this.fileService.createPdf(contentDataURL);
        this.fileService.savePdf(pdf, this.fileName);
        this.loading = false;
        this.exporting = false;
      });
    } else {
      const createFirstPage = () => {
        var page1Div = document.getElementById('cvDiv');

        html2canvas(page1Div, { useCORS: true }).then(canvas => {
          const contentDataURL = canvas.toDataURL('image/png');
          pdf = this.fileService.createPdf(contentDataURL);
          $('#carouselCv').carousel(1); // Now go to page 2
        });
      }

      $('#carouselCv').on('slid.bs.carousel', (e) => {
        if (!this.exporting) return;
        const pageIndex = $(e.relatedTarget).index();

        if (pageIndex === 0) {
          // Page 1
          createFirstPage();
        } else if (pdf !== null) {
          // Page 2
          var page2Div = document.getElementById('cvDivPage2');
          html2canvas(page2Div).then(canvas2 => {
            const contentDataURL2 = canvas2.toDataURL('image/png');
            this.fileService.addPageToPdf(pdf, contentDataURL2);

            if (upload) {
              // Upload PDF to Firebase
              const filename = this.fileName + '.pdf';
              var pdfBlob = pdf.output('blob', filename);
              pdfBlob.name = filename;
              this.dataService.uploadFile(pdfBlob).subscribe(() => {
                this.loading = false;
                this.exporting = false;
              });
            } else {
              // Save PDF locally
              this.fileService.savePdf(pdf, this.fileName);
              this.loading = false;
              this.exporting = false;
            }
          });
        }
      });

      // Start with page 1
      const currentIndex = $('#carouselCv').find('.active').index();
      if (currentIndex === 0) {
        createFirstPage();
      } else {
        $('#carouselCv').carousel(0); // Not on first page, switch
      }
    }
  }
}